import React from "react"
import { Carousel } from "react-responsive-carousel"
import { handleImageLinkDrage, sliderImgUrl } from "@helpers/Helpers"
import { SliderProps } from "@helpers/Props"
import { Link } from "react-router-dom"
import './ShopHomeCarousel.css'

const ShopHomeCarousel: React.FC<SliderProps> = ({ items }) => {
  return (
    <div className="home-slider-container shop-home-slider">
      {
        items.length > 0 && <Carousel
          infiniteLoop
          // autoPlay
          interval={5000}
          showIndicators
          showThumbs={false}
          transitionTime={600}
          emulateTouch
          swipeScrollTolerance={100}
          verticalSwipe={"natural"}
          preventMovementUntilSwipeScrollTolerance={true}
          showStatus
          showArrows={true}
          renderIndicator={(clickHandler, isSelected, index) => (
            <div
              className={`my-indicator ${isSelected ? 'selected bg-yellow' : ''}`}
              onClick={clickHandler}
              key={index}
            >
              {`0${index + 1}`}
            </div>
          )}
        >
          {items.map((item, index) => (
            <Link key={index} to={''} onDragStart={handleImageLinkDrage} >
              <div className="sliderImg">
                <img alt={item.title} src={`${sliderImgUrl}/${item.image}`} onDragStart={handleImageLinkDrage} />
              </div>
            </Link>
          ))}
        </Carousel>}
    </div>
  )
}

export default ShopHomeCarousel