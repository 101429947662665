import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import Icons from '@helpers/IconsHelper';
import './NewsEmail.css'
import { SubmitButton, formHelper } from '@helpers/Index';
import { emailSchema } from '@helpers/YupHelper';
import { useApiRequest } from '@helpers/ApiRequest';
import { ErrorNotify, FrCustomeErrorNorify, notify } from '@helpers/Toastify';
import { useWindowWidthContext } from '@contexts/WindowWidthContext';

const NewsEmail: FC = () => {
    const { windowWidth } = useWindowWidthContext()
    const apiRequest = useApiRequest();
    const [isLoading, setIsLoading] = useState(false);

    const formik = formHelper.useFormik({
        initialValues: {
            email: ""
        },
        validationSchema: emailSchema,
        onSubmit: async (values, { resetForm }) => {
            try {
                setIsLoading(true)

                const checkResponse = await apiRequest({
                    route: `check-news-email/${values.email}`,
                    method: 'GET',
                    body: values
                })

                if (checkResponse.status === 200) {
                    console.log(checkResponse.data.found);

                    if (checkResponse.data.found === false) {
                        const response = await apiRequest({
                            route: `news_emails`,
                            method: 'POST',
                            body: values
                        })

                        if (response.status === 201) {
                            notify('email added')
                            resetForm()
                            setIsLoading(false)
                        } else {
                            FrCustomeErrorNorify()
                            resetForm()
                        }
                    } else {
                        ErrorNotify('Déja ajouté')
                        resetForm()
                    }
                } else {

                }

            } catch (error) {
                setIsLoading(false)
            }
        },
    });




    return (

        <>
            {/* <form onSubmit={formik.handleSubmit}>
            <div className="input-group">
                <formHelper.FormInput
                    label=""
                    spanValue="Email"
                    name="email"
                    type="email"
                    value={formik.values.email}
                    className={formik.touched.email && formik.errors.email ? "is-invalid" : ""}
                    touched={formik.touched}
                    errors={formik.errors}
                    handleChange={formik.handleChange}
                    handleBlur={formik.handleBlur}
                />

                <div className="input-group-append">
                    <span className="input-group-textt">
                        <Icons.FaTelegramPlane className='clr-dark sendemail' />
                    </span>
                </div>
            </div>
        </form> */}

            <div className="news-email d-flex-center bg-cream min-h-20rem">
                <div className="container d-flex-center keep-up">
                    <div className="row d-flex-between-center">
                        <div className="col-lg-4 col-12 d-flex-start text-start">
                            <Icons.RiMailOpenFill className='clr-blue fs-40px me-2 mb-px-17' />  <p className='clr-blue fs-35px fw-600 ff-lato-reg'>Restez informé(e)</p>
                        </div>

                        <div className="col-lg-5 col-12">
                            <p className='clr-blue'>Inscrivez-vous à la newsletter et recevez dans votre mailbox les surprises et gourmandises de Leonidas.</p>
                        </div>

                        <div className="col-lg-3 col-12 text-end">
                            <SubmitButton btnLabel={`Je m'inscris`}
                                className='py-13px submit-btn' />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default NewsEmail;