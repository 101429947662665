import { FC, useState, useEffect } from 'react'
import { useInfiniteQuery } from 'react-query'
import { useLocation, useParams } from 'react-router-dom'
import { ProductProps } from '@helpers/Props'
import { ReactHelmet, apiUrl, categorieImgUrl, valueText } from '@helpers/Helpers'
import { hooks, formHelper, allProductBg } from '@helpers/Index'
import { applyFiltersAndSort, handleSortChange } from '@components/ProductFilter/applyFiltersAndSort'
import Layout from '../../../../layouts/Layout'
import ProductFilter from '@components/ProductFilter/ProductFilter'
import ProductFetch from '@components/ProductFilter/ProductFetch'
import BrandMultiCarousel from '@components/Carousel/BrandMultiCarousel/BrandMultiCarousel'
import './ShopBoutique.css'
import BrandSwiper from '@components/Carousel/BrandSwiper/BrandSwiper'
import ShopLayout from '../../../../layouts/ShopLayout'
import { useWindowWidthContext } from '@contexts/WindowWidthContext'
import { useCategoriesContext } from '@contexts/CategoriesContext'

const ShopBoutique: FC = () => {

  const { windowWidth } = useWindowWidthContext()
  const { categories } = useCategoriesContext()
  const { categorie } = useParams()

  const [sortBy, setSortBy] = useState('')
  const [isGrid, setIsGrid] = useState(true)
  const [isFilter, setIsFilter] = useState(true)
  const [sortByDate, setSortByDate] = useState("")
  const [isLoadingData, setIsLoadingData] = useState(true)
  const [productCategory, setProductCategory] = useState<number>()
  const [isLoadingMoreData, setIsLoadingMoreData] = useState(false)
  const path = useLocation()



  useEffect(() => {
    const getCategory = categories.find(item => categorie === item.slug);
    setProductCategory(getCategory?.id);

  }, [categorie]);


  const route =
    productCategory ?
      `total-products-bycategory/${categorie}`
      : 'total-products'

  const { minPrice, maxPrice, totalProducts } = hooks.useProductInfo(route)
  const [filteredPrice, setFilteredPrice] = useState<number[]>([minPrice, 2000])

  const {
    data,
    fetchNextPage,
    isFetchingNextPage,
    isLoading
  } = useInfiniteQuery<ProductProps[], Error>(
    ['products', { productCategory, categorie }],
    async ({ pageParam = 1 }) => {
      setIsLoadingData(true)
      setIsLoadingMoreData(true)
      const response = await formHelper.axios.get(`${apiUrl}/products.json`, {
        params: {
          disponibilite: 'enstock',
          categorie: productCategory || null,
          page: pageParam,
          itemsPerPage: 16,
        },
      })
      setIsLoadingData(false)
      setIsLoadingMoreData(false)

      return response.data
    },
    {
      getNextPageParam: (lastPage, pages) => pages.length + 1,
    }
  )

  const products = data?.pages.flat() || []
  let filteredData = applyFiltersAndSort([...products], filteredPrice, sortBy, sortByDate)


  useEffect(() => {
    if (maxPrice) {
      setFilteredPrice([minPrice, maxPrice])
    }
  }, [maxPrice])

  const handlePriceChange = (event: Event, newValue: number | number[]) => {
    setFilteredPrice(newValue as number[])
  }

  const handleSortChangeWrapper = (event: React.ChangeEvent<HTMLSelectElement>) => {
    handleSortChange(event.target.value, setSortBy, setSortByDate, setIsFilter)
  }

  const selectedCategory = categories.find(item => categorie === item.slug);

  const image =
    path.pathname === '/shop/tous-les-produits'
      ? allProductBg
      : path.pathname === '/shop/cartes-cadeau'
        ? allProductBg
        :
        `${categorieImgUrl}/${selectedCategory?.image}`

  const containerTitle =
    path.pathname === '/shop/tous-les-produits'
      ? 'Tous les produits'
      : path.pathname === '/shop/cartes-cadeau'
        ? 'Le cadeau idéal'
        :
        `${selectedCategory?.title}`


  return (
    <ShopLayout
      containerTitle={`${selectedCategory?.title}`}
      containerBackground={`${categorieImgUrl}/${selectedCategory?.image}`}
      containerUrl={`${selectedCategory?.title}`}
      haveBreadCrumbs
      haveImage={false}
      haveSecondUrl>

      <ReactHelmet title='Leonidas - Produits' />

      <div className={`boutique mb-3rem ${isGrid ? 'isgrid' : 'isrow'}`}>
        <div className="container">
          <div className="row d-flex justify-content-center">
            {products ?
              <ProductFilter
                url=''
                sortBy={sortBy}
                isFilter={isFilter}
                maxPrice={maxPrice}
                categories={categories}
                windowWidth={windowWidth}
                filteredPrice={filteredPrice}
                valueText={valueText}
                setIsGrid={(setIsGrid)}
                setIsFilter={setIsFilter}
                handlePriceChange={handlePriceChange}
                setProductCategory={setProductCategory}
                handleSortChange={handleSortChangeWrapper}
              />
              :
              null
            }

            <ProductFetch
              isGrid={isGrid}
              windowWidth={windowWidth}
              filteredData={filteredData}
              isLoadingData={isLoading}
              totalProducts={totalProducts}
              fetchNextPage={fetchNextPage}
              isLoadingMoreData={isLoadingMoreData}
              isFetchingNextPage={isFetchingNextPage}
            />
          </div>
        </div>
      </div>
    </ShopLayout>
  )
}

export default ShopBoutique