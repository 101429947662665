import { FC } from 'react';
import { Link } from 'react-router-dom';
import { MDBFooter, MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import './FooterStyle.css'
import Icons from '@helpers/IconsHelper';

const ShopFooter: FC = () => {
  const less = '>'

  return (
    <>
      <div className="shop-footer">
        <div className="container">
          <div className="row mt-4">
            <div className="col-4">
              <h6 className='fw-600'>LEONIDAS</h6>
              <p className=''><span className='clr-yellow fs-26px'>{less}</span> <span className='clr-blue text-upper'>shops</span> </p>
              <p className=''><span className='clr-yellow fs-26px'>{less}</span> <span className='clr-blue text-upper'>chocoloates</span> </p>
            </div>
            <div className="col-4">
              <h6 className='fw-600'>LEONIDAS</h6>
              <p className=''><span className='clr-yellow fs-26px'>{less}</span> <span className='clr-blue text-upper'>GOURMET DELIGHTS</span> </p>
              <p className=''><span className='clr-yellow fs-26px'>{less}</span> <span className='clr-blue text-upper'>BLISSFUL MOMENTS</span> </p>
            </div>
            <div className="col-4">
              <h6 className='fw-600'>LEONIDAS</h6>
              <p className=''><span className='clr-yellow fs-26px'>{less}</span> <span className='clr-blue text-upper'>shops</span> </p>
              <p className=''><span className='clr-yellow fs-26px'>{less}</span> <span className='clr-blue text-upper'>LEGAL DETAILS</span> </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShopFooter;