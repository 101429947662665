import React, { ReactNode } from 'react'
import { Footer, HeaderContainer, Navbar, TopHeader } from '@helpers/Index'
import { ReactHelmet } from '@helpers/Helpers'
import AboutUs from '@components/AboutUs/AboutUs'
import NewsEmail from '@components/NewsEmail/NewsEmail'

interface LayoutProps {
    children: ReactNode
    helmetTitle?: string
    containerTitle?: string
    className?: string
}

const Layout: React.FC<LayoutProps> = ({ children, helmetTitle, containerTitle, className }) => {
    return (
        <React.Fragment>
            {/* <ReactHelmet title={helmetTitle} />
            <HeaderContainer className={className} title={containerTitle} /> */}
            <TopHeader />
            <Navbar />
            <main>{children}</main>
            <NewsEmail />
            <AboutUs />
            <Footer />
        </React.Fragment>
    )
}

export default Layout