import React, { useEffect } from 'react';
import Navbar from '@components/Navbar/Navbar';
import TopHeader from '@components/TopHeader/TopHeader';
import { Link, useNavigate } from 'react-router-dom';
import Footer from '@components/Footer/Footer';
import HeaderContainer from '@components/HeaderContainer/HeaderContainer';
import { RxDashboard } from 'react-icons/rx'
import { MdOutlineManageAccounts, MdOutlineRateReview } from 'react-icons/md'
// import { TbDownload } from 'react-icons/tb'
import { IoMdLocate } from 'react-icons/io'
import { RiCoupon5Line } from 'react-icons/ri'
import { FiLogOut, FiShoppingBag } from 'react-icons/fi'
import { useShoppingCart } from '../../../../contexts/ShoppingCartContext';
import './UserDashStyle.css'
import ShopLayout from '../../../../layouts/ShopLayout';
import { pagesBg } from '@helpers/Index';

interface UserDashboardProps {
    secondComponent: React.FC;
}

const UserDashboard: React.FC<UserDashboardProps> = ({ secondComponent: SecondComponent }) => {
    const leon_token = localStorage.getItem('leon_token');
    const { emptyShoppingCart } = useShoppingCart();
    const navigate = useNavigate();
    useEffect(() => {
        if (!leon_token) {
            navigate('/shop/login');
        }
    }, [leon_token, navigate]);

    const logout = () => {
        // localStorage.clear();
        emptyShoppingCart();
        localStorage.removeItem('leon_token');
        localStorage.removeItem('user');
        navigate('/shop/login');
    }

    return (
        <ShopLayout containerTitle='Compte'
            containerBackground={pagesBg}
            containerUrl=''
            haveBreadCrumbs
            haveImage={false}
            haveSecondUrl={false}>

            <div className="user-dash">
                <div className="productcontainer container d-flexd justify-content-center">
                    <div className="row d-flex justify-content-center">

                        <div className="col-11 col-md-4 col-lg-3 col-xl-3 col-xxl-2 me-5s me-md-1 me-lg-1 mb-5 pb-4 user-account">
                            <div className="container mt-5">

                                <div className="row">
                                    <div className="user-dash-title ">
                                        <div className="user-dash-row ">
                                            <Link to='/shop/my-account' className='nav-link'>
                                                <RxDashboard className='mb-1 me-3 dash-icons' />
                                                <span>TABLEAU DE BORD</span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <hr className="hr-tag" />

                                <div className="row">
                                    <div className="user-dash-title ">
                                        <div className="user-dash-row ">
                                            <Link to='/shop/my-account/orders' className='nav-link'>
                                                <FiShoppingBag className='mb-1 me-3 dash-icons' />
                                                <span>COMMANDES</span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <hr className="hr-tag" />

                                {/* <div className="row">
                        <div className="user-dash-title ">
                            <div className="user-dash-row ">
                                <Link to='/shop/my-account/edit-account' className='nav-link'>
                                    <TbDownload className='mb-1 me-3 dash-icons' />
                                    <span>TÉLÉCHARGEMENTS</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <hr className="hr-tag" /> */}

                                {/* <div className="row">
                        <div className="user-dash-title ">
                            <div className="user-dash-row ">
                                <Link to='/shop/my-account/code-promo' className='nav-link'>
                                    <RiCoupon5Line className='mb-1 me-3 dash-icons' />
                                    <span>CODES PROMO</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <hr className="hr-tag" /> */}

                                <div className="row">
                                    <div className="user-dash-title ">
                                        <div className="user-dash-row ">
                                            <Link to='/shop/my-account/reviews' className='nav-link'>
                                                <MdOutlineRateReview className='mb-1 me-3 dash-icons' />
                                                <span>Avis</span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <hr className="hr-tag" />

                                <div className="row">
                                    <div className="user-dash-title ">
                                        <div className="user-dash-row ">
                                            <Link to='/shop/my-account/edit-address' className='nav-link'>
                                                <IoMdLocate className='mb-1 me-3 dash-icons' />
                                                <span>ADRESSES</span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <hr className="hr-tag" />

                                <div className="row">
                                    <div className="user-dash-title ">
                                        <div className="user-dash-row ">
                                            <Link to='/shop/my-account/edit-account' className='nav-link'>
                                                <MdOutlineManageAccounts className='mb-1 me-3 dash-icons' />
                                                <span>DÉTAILS DU COMPTE</span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <hr className="hr-tag" />

                                <div className="row">
                                    <div className="user-dash-title ">

                                        <div className="user-dash-row ">
                                            <button onClick={() => logout()} className='btn nav-link'>
                                                <FiLogOut className='mb-1 me-3 dash-icons' />
                                                <span>DÉCONNEXION</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <hr className="hr-tag" />

                            </div>

                        </div>

                        <SecondComponent />

                    </div>
                </div>
            </div>
        </ShopLayout>
    );
};

export { UserDashboard };