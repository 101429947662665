import { FC, useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useApiRequest } from '@helpers/ApiRequest';
import { ProductProps } from '@helpers/Props';
import {   logo } from '@helpers/Index';
import useClickOutside from '@hooks/useClickOutside';
import OffCanvas from '../OffCanvas/OffCanvas';
import ReactLink from '../ReactLink/ReactLink';
import './NavBarStyle.css'
import { useWindowWidthContext } from '@contexts/WindowWidthContext';


let timeout: NodeJS.Timeout;
let marquetTimeout: NodeJS.Timeout;

const Navbar: FC = () => {

  useEffect(() => {
    return () => {
      clearTimeout(timeout);
      clearTimeout(marquetTimeout);
    };
  }, []);

  // Global
  const navigate = useNavigate();
  const apiRequest = useApiRequest()

  // Contexts
  const { windowWidth } = useWindowWidthContext()

  // States
  const [searchBoxVisible, setSearchBoxVisible] = useState(false);
  const [ShoppingCartBoxVisible, setShoppingCartBoxVisible] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isMarqueDropdownOpen, setIsMarqueDropdownOpen] = useState(false);
  const [isGiftDropdownOpen, setIsGiftDropdownOpen] = useState(false);
  const [products, setProducts] = useState<ProductProps[]>([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [textValue, setTextValue] = useState('');

  // ShoppingCartBox close
  const elementRef = useRef<HTMLDivElement>(null);
  const blockRef = useRef<HTMLDivElement>(null);

  // OffCanvas close
  const closeBtnRef = useRef<HTMLButtonElement | null>(null);
  const closeCBtnRef = useRef<HTMLButtonElement | null>(null);

  const closeOffCanvas = () => {
    if (closeBtnRef.current) {
      closeBtnRef.current.click();
    }
  };

  const closeCOffCanvas = () => {
    if (closeCBtnRef.current) {
      closeCBtnRef.current.click();
    }
  };

  const handleShoppingCartBoxClose = () => {
    setShoppingCartBoxVisible(false)
  };

  useClickOutside(elementRef, handleShoppingCartBoxClose, blockRef);


  const openModal = (product: any) => {
    setSelectedProduct(product);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleDropdownMouseEnter = () => {
    if (windowWidth > 992) {
      clearTimeout(timeout);
      setIsDropdownOpen(true);
    }
  };


  const handleDropdownMouseLeave = () => {
    if (windowWidth > 992) {
      timeout = setTimeout(() => {
        setIsDropdownOpen(false);
      }, 200);
    }
  };

  const handleMarqueDropdownMouseEnter = () => {
    clearTimeout(marquetTimeout);
    setIsMarqueDropdownOpen(true);
  };

  const handleMarqueDropdownMouseLeave = () => {
    marquetTimeout = setTimeout(() => {
      setIsMarqueDropdownOpen(false);
    }, 200);
  };



  useEffect(() => {
    if (!searchBoxVisible) {
      setProducts([])
      setTextValue('');
    }
  }, [searchBoxVisible])

  const marquesContent =
    <div className="row ox-hidden" >

    </div>


  const categoriesContent =
    <div className="row">

    </div>



  return (
    <>
      <header className="header-nav w-100 d-flex justify-content-start align-items-center">
        <div className="containerr w-100 mx-5 top-container">
          <div className="row justify-content-end">

            <div className="col-xxl-9 col-xl-9 col-lg-12 col-md-12 col-sm-12 col-12  mt-2">
              <nav className="navbar navbar-expand-xxl navbar-right">
                <div className="container-fluid d-flex justify-content-start align-items-center">

                  <div className="responsive-icon">
                    <button className={`navbar-toggler ${windowWidth < 992 ? 'collapsed' : ''}`} type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                      <span className="navbar-toggler-icon"></span>
                    </button>
                  </div>

                  <div className={`navbar-brand m-logo ${windowWidth < 992 ? 'ms3' : 'ms-0me-4'}`}>
                    <Link to="/">
                      <img src={logo} alt="leonidas" className='leonidas leonidas-logo' />
                    </Link>
                  </div>

                  <div className="w-100 collapse navbar-collapse" id="navbarNavDropdown">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">

                      <li className="nav-item px-5px">
                        <Link className="nav-link navbar-link " to="/shop">Boutiques</Link>
                      </li>

                      {/* Start Categorie */}
                      <li
                        className={`nav-item px-5px dropdown`}
                        onMouseEnter={handleDropdownMouseEnter}
                        onMouseLeave={handleDropdownMouseLeave} >
                        {
                          windowWidth > 992
                            ?
                            <a
                              className="nav-link navbar-link dropdown-toggle"
                              href="#"
                              id="navbarDropdownMenuLink"
                              role="button"
                              data-bs-toggle="dropdown" aria-expanded="false"
                              aria-label="categories"
                            >
                              Chocolats & Gourmandises
                            </a>
                            :
                            <a
                              className="nav-link navbar-link dropdownstoggle"
                              href="#"
                              data-bs-toggle="offcanvas"
                              data-bs-target="#offcanvascategorie"
                              aria-controls="offcanvascategorie"
                            >
                              Chocolats & Gourmandises
                            </a>
                        }

                        {windowWidth > 992
                          ?
                          <ul className={`dropdown-menu dropdown-menu-categorie ${windowWidth > 520 || true ? isDropdownOpen ? 'show' : '' : ''}`} aria-labelledby="navbarDropdownMenuLink">
                            {categoriesContent}
                          </ul>
                          :
                          <OffCanvas
                            content={categoriesContent}
                            label='Nos Categories'
                            id='offcanvascategorie'
                            closeBtnRef={closeCBtnRef}
                            position='right'
                          />
                        }

                      </li>
                      {/* End Categorie */}

                      {/* Start Marque */}
                      <li className="nav-item px-5px dropdown"
                        onMouseEnter={handleMarqueDropdownMouseEnter}
                        onMouseLeave={handleMarqueDropdownMouseLeave}
                        onClick={() => setIsDropdownOpen(false)}>

                        {
                          windowWidth > 992 ?
                            <a className="nav-link navbar-link  dropdown-toggle"
                              href="#" id="navbarDropdownMenuLink"
                              role="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                              aria-label="marques"
                            >
                              Cadeaux
                            </a>
                            :
                            <a
                              className="nav-link navbar-link dropdownstoggle" href="#"
                              data-bs-toggle="offcanvas" data-bs-target="#offcanvasmarque" aria-controls="offcanvasmarque"
                            >
                              Cadeaux
                            </a>
                        }

                        {
                          windowWidth > 992
                            ?
                            <ul className={`dropdown-menu dropdown-menu-categorie ${windowWidth > 520 ? isMarqueDropdownOpen ? 'show' : '' : ''}`} aria-labelledby="navbarDropdownMenuLink">
                              {marquesContent}
                            </ul>
                            :
                            <OffCanvas
                              content={marquesContent}
                              label='Nos Marques'
                              id='offcanvasmarque'
                              closeBtnRef={closeBtnRef}
                              position='left'
                            />
                        }

                      </li>
                      {/* End Marque */}

                      {/* Start Marque */}
                      <li className="nav-item px-5px dropdown"
                        onMouseEnter={handleMarqueDropdownMouseEnter}
                        onMouseLeave={handleMarqueDropdownMouseLeave}
                        onClick={() => setIsDropdownOpen(false)}>

                        {
                          windowWidth > 992 ?
                            <a className="nav-link navbar-link  dropdown-toggle"
                              href="#" id="navbarDropdownMenuLink"
                              role="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                              aria-label="marques"
                            >
                              Avenir durable
                            </a>
                            :
                            <a
                              className="nav-link navbar-link dropdownstoggle" href="#"
                              data-bs-toggle="offcanvas" data-bs-target="#offcanvasmarque" aria-controls="offcanvasmarque"
                            >
                              Avenir durable
                            </a>
                        }

                        {
                          windowWidth > 992
                            ?
                            <ul className={`dropdown-menu dropdown-menu-categorie ${windowWidth > 520 ? isMarqueDropdownOpen ? 'show' : '' : ''}`} aria-labelledby="navbarDropdownMenuLink">
                              {marquesContent}
                            </ul>
                            :
                            <OffCanvas
                              content={marquesContent}
                              label='Nos Marques'
                              id='offcanvasmarque'
                              closeBtnRef={closeBtnRef}
                              position='left'
                            />
                        }

                      </li>
                      {/* End Marque */}


                      {/* Start Marque */}
                      <li className="nav-item px-5px dropdown"
                        onMouseEnter={handleMarqueDropdownMouseEnter}
                        onMouseLeave={handleMarqueDropdownMouseLeave}
                        onClick={() => setIsDropdownOpen(false)}>

                        {
                          windowWidth > 992 ?
                            <a className="nav-link navbar-link  dropdown-toggle"
                              href="#" id="navbarDropdownMenuLink"
                              role="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                              aria-label="marques"
                            >
                              A propos
                            </a>
                            :
                            <a
                              className="nav-link navbar-link dropdownstoggle" href="#"
                              data-bs-toggle="offcanvas" data-bs-target="#offcanvasmarque" aria-controls="offcanvasmarque"
                            >
                              A propos
                            </a>
                        }

                        {windowWidth > 992
                          ?
                          <ul className={`dropdown-menu dropdown-menu-categorie ${windowWidth > 520 ? isMarqueDropdownOpen ? 'show' : '' : ''}`} aria-labelledby="navbarDropdownMenuLink">
                            {marquesContent}
                          </ul>
                          :
                          <OffCanvas
                            content={marquesContent}
                            label='Nos Marques'
                            id='offcanvasmarque'
                            closeBtnRef={closeBtnRef}
                            position='left'
                          />
                        }

                      </li>
                      {/* End Marque */}

                      <li className="nav-item px-5px d-none">
                        <ReactLink to='/shop' label='Commander en ligne' className='py-13px ms-10px' />
                      </li>

                    </ul>
                  </div>
                </div>
              </nav>
            </div>

            <div className={`col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-8 col-5  text-${windowWidth > 578 ? 'end' : 'center'} dd-flex justify-content-end align-items-center user-header`}>
              <div className={`d-inline-flex text-${windowWidth > 578 ? 'end' : 'center'} mb-1 icon-items m-searchx`}>

              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Navbar;