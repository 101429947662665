import { FC } from 'react';
import { Link } from 'react-router-dom';
import { MDBFooter, MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import './FooterStyle.css'
import Icons from '@helpers/IconsHelper';

const Footer: FC = () => {

  return (
    <>
      <MDBFooter className='text-center text-lg-start bg-footer ff-lato-reg clr-light ff-lato-reg00'>
        <section className=''>
          <MDBContainer className='text-center text-md-start mt5 pt-2'>
            <MDBRow className='mt-3'>
              <MDBCol md='6' lg='6' xl='6' className='mx-auto mb-4'>
                © {new Date().getFullYear()} Copyright
                <Link className='text-reset ps-1' to='/'>
                  Leonidas
                </Link>
              </MDBCol>

              <MDBCol md='6' lg='6' xl='6' className='mx-auto mb-4'>
                Extend the Leonidas experience on the social networks:
                <Icons.FaFacebookF className='mx-1' />
                <Icons.RiInstagramFill className='mx-1' />
                <Icons.FaYoutube className='mx-1' />
              </MDBCol>

            </MDBRow>
          </MDBContainer>
        </section>
      </MDBFooter>
    </>
  );
};

export default Footer;