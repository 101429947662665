import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from '../pages/Home/Home';
import Login from '../pages/Auth/Login';
import Register from '../pages/Auth/Register';
import ForgotPassword from '../pages/Auth/ForgotPassword';
import ResetPassword from '../pages/Auth/ResetPassword';
import Error404 from '../pages/Errors/Error404';
import ShopHome from '../pages/Shop/Pages/ShopHome/ShopHome';
import ShopBoutique from '../pages/Shop/Pages/ShopBoutique/ShopBoutique';
import ProductDetails from '../pages/ProductDetails/ProductDetails';
import { ShoppingCart } from '../pages/ShoppingCart/ShoppingCart';
import { DashContent } from '../pages/Account/pages/UserDashboard/DashContent';
import { EditAccount } from '../pages/Account/pages/UserEditAccount/EditAccount';
import { UserOrders } from '../pages/Account/pages/Orders/UserOrders';
import { UserOrderDetails } from '../pages/Account/pages/Orders/UserOrderDetails';
import { EditAddress } from '../pages/Account/pages/UserAdresse/EditAddress';
import { CodePromo } from '../pages/Account/pages/CodePromo/CodePromo';
import { UserReviews } from '../pages/Account/pages/UserReviews/UserReviews';
import AdresseFacturation from '../pages/Account/pages/UserAdresse/pages/AdresseFacturation';
import AdresseLivraison from '../pages/Account/pages/UserAdresse/pages/AdresseLivraison';
import Checkout from '../pages/Checkout/Checkout';
import AllTheMoments from '@pages/Moments/Pages/AllTheMoments/AllTheMoments';
const Routing: React.FC = () => {

  return (
    <Router basename="/">
      <Routes>
        {/* -------- Global */}
        <Route path="*" element={<Error404 />} />
        <Route path="/" element={<Home />} />
        <Route path="/shop" element={<ShopHome />} />

        {/* -------- Boutique */}
        <Route path="/shop/tous-les-produits" element={<ShopBoutique />} />
        <Route path="/shop/:categorie" element={<ShopBoutique />} />
        <Route path="/shop/cartes-cadeau" element={<ShopHome />} />
        <Route path="/shop/produit/:slug" element={<ProductDetails />} />

        {/* -------- Cart */}
        <Route path="/shop/my-cart" element={<ShoppingCart />} />
        <Route path="/shop/checkout" element={<Checkout />} />



        {/* -------- Auth */}
        <Route path="/shop/login" element={<Login />} />
        <Route path="/shop/register" element={<Register />} />
        <Route path="/shop/forgot-password" element={<ForgotPassword />} />
        <Route path="/shop/reset_password/:token" element={<ResetPassword />} />

        {/* -------- Account */}
        <Route path="/shop/my-account" element={<DashContent />} />
        <Route path="/shop/my-account/edit-account" element={<EditAccount />} />
        <Route path="/shop/my-account/orders" element={<UserOrders />} />
        <Route path="/shop/my-account/orders/order-details/:id" element={<UserOrderDetails />} />
        <Route path="/shop/my-account/edit-address" element={<EditAddress />} />
        <Route path="/shop/my-account/code-promo" element={<CodePromo />} />
        <Route path="/shop/my-account/reviews" element={<UserReviews />} />
        <Route path="/shop/my-account/edit-address/facturation" element={<AdresseFacturation />} />
        <Route path="/shop/my-account/edit-address/livraison" element={<AdresseLivraison />} />
        <Route path="/shop/my-account/edit-address/livraison/:adresseId" element={<AdresseLivraison />} />
        <Route path="/shop/my-account/edit-address/facturation/:adresseId" element={<AdresseFacturation />} />

        {/* -------- Moments */}
        <Route path="/moments-de-bonheur" element={<AllTheMoments />} />
        <Route path="/moments-de-bonheur" element={<AllTheMoments />} />

      </Routes>
    </Router>
  );
};

export { Routing };