import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import Icons from '@helpers/IconsHelper';
import './ShopNewsEmail.css'
import { SubmitButton,  formHelper } from '@helpers/Index';
import { emailSchema } from '@helpers/YupHelper';
import { useApiRequest } from '@helpers/ApiRequest';
import { ErrorNotify, FrCustomeErrorNorify, notify } from '@helpers/Toastify';

const ShopNewsEmail: FC = () => {
    const apiRequest = useApiRequest();
    const [isLoading, setIsLoading] = useState(false);

    const formik = formHelper.useFormik({
        initialValues: {
            email: ""
        },
        validationSchema: emailSchema,
        onSubmit: async (values, { resetForm }) => {
            try {
                setIsLoading(true)

                const checkResponse = await apiRequest({
                    route: `check-news-email/${values.email}`,
                    method: 'GET',
                    body: values
                })

                if (checkResponse.status === 200) {
                    console.log(checkResponse.data.found);

                    if (checkResponse.data.found === false) {
                        const response = await apiRequest({
                            route: `news_emails`,
                            method: 'POST',
                            body: values
                        })

                        if (response.status === 201) {
                            notify('email added')
                            resetForm()
                            setIsLoading(false)
                        } else {
                            FrCustomeErrorNorify()
                            resetForm()
                        }
                    } else {
                        ErrorNotify('Déja ajouté')
                        resetForm()
                    }
                } else {

                }

            } catch (error) {
                setIsLoading(false)
            }
        },
    });


    return (
        <div className="shop-news-email min-h-30rem d-flex-center ">
            <div className="container d-flex-center">
                <div className="row text-center d-flex-center max-w-40rem">
                    <div className="col-12 py-1rem">
                        <Icons.BsEnvelopePaper className='clr-light fs-5rem' />
                    </div>

                    <div className="col-12 py-1rem">
                        <h3 className='fw-600 clr-light ff-ubuntu-m'>Restez à jour</h3>
                    </div>

                    <div className="col-12 py-1rem max-w-30rem">
                        <p className='clr-light fs-17px'>Inscrivez-vous à notre newsletter et restez informés des dernières nouveautés et tendances</p>
                    </div>

                    <div className="col-12 py-1rem">
                        <form onSubmit={formik.handleSubmit}>
                            <div className="row d-flex-center">
                                <div className="col-4">
                                    <div className="input-group">
                                        <formHelper.FormInput
                                            label=""
                                            spanValue=""
                                            name="email"
                                            type="email"
                                            value={formik.values.email}
                                            className={formik.touched.email && formik.errors.email ? "is-invalid h-3rem" : ""}
                                            touched={formik.touched}
                                            errors={formik.errors}
                                            handleChange={formik.handleChange}
                                            handleBlur={formik.handleBlur}
                                            feedbackStyle='position-absolute'
                                        />
                                    </div>
                                </div>
                                <div className="col-4">
                                    <SubmitButton btnLabel='Inscrivez-vous' className='px-1rem py-10px submit-btn-1' />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ShopNewsEmail;